import {Alert, AlertIcon} from '@chakra-ui/alert';
import {Image} from '@chakra-ui/image';
import {Flex, Heading, Stack, Text} from '@chakra-ui/layout';
import {Skeleton} from '@chakra-ui/skeleton';
import {useParams} from 'react-router-dom';
import {useShopQuery} from '../../generated/graphql';

export const ShopPage = () => {
  const {id} = useParams<{id: string}>();

  const [{data, fetching, error}] = useShopQuery({
    variables: {id},
  });

  if (error) {
    return (
      <Alert status="error">
        <AlertIcon />
        Error: {error.message}
      </Alert>
    );
  }

  return (
    <Skeleton isLoaded={!fetching}>
      <Stack p="6">
        <Flex mb="2" align="center">
          <Image
            boxSize="48px"
            borderRadius="sm"
            src={data?.shop?.logo?.urls?.small as string}
          ></Image>
          <Heading size="lg" ml="4">
            {data?.shop?.name}
          </Heading>
        </Flex>
        <Text fontSize="l">
          <Text as="b">Status:</Text> {data?.shop?.status}
        </Text>
        <Text fontSize="l">
          <Text as="b">Description:</Text> {data?.shop?.description}
        </Text>
        <Text fontSize="l">
          <Text as="b">Emails:</Text>{' '}
          {data?.shop?.emails?.map((email) => email?.address).join(', ')}
        </Text>
        <Text fontSize="l">
          <Text as="b">Currency:</Text> {data?.shop?.currency.code}
        </Text>
        <Text fontSize="l">
          <Text as="b">Timezone:</Text> {data?.shop?.timezone}
        </Text>
      </Stack>
    </Skeleton>
  );
};
