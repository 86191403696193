import {Alert, AlertIcon, AlertTitle, AlertDescription} from '@chakra-ui/react';

export const AccessDeniedPage = () => {
  return (
    <Alert status="error">
      <AlertIcon />
      <AlertTitle mr={2}>Access denied!</AlertTitle>
      <AlertDescription>You are not allowed to view this page</AlertDescription>
    </Alert>
  );
};
