import {Alert, AlertIcon} from '@chakra-ui/alert';
import {IconButton} from '@chakra-ui/button';
import {RepeatIcon} from '@chakra-ui/icons';
import {Flex, Link, Stack} from '@chakra-ui/layout';
import {Skeleton} from '@chakra-ui/skeleton';
import {Table, Tbody, Td, Th, Thead, Tr} from '@chakra-ui/table';
import {ShopStatus, useGetShopsQuery} from '../../generated/graphql';
import {ApproveActions} from '../../components/ApproveActions';
import {Link as ReactRouterLink} from 'react-router-dom';
import {Image} from '@chakra-ui/image';

export const HomePage = () => {
  const [result, reexecuteQuery] = useGetShopsQuery({
    variables: {
      status: ShopStatus.InReview,
    },
  });
  const {data, fetching, error} = result;

  if (error)
    return (
      <Alert status="error">
        <AlertIcon />
        Error: {error.message}
      </Alert>
    );

  return (
    <Skeleton isLoaded={!fetching}>
      <Stack>
        <Table>
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Status</Th>
              <Th>Reject reason</Th>
              <Th>Proof file</Th>
              <Th>
                Reload
                <IconButton
                  onClick={() =>
                    reexecuteQuery({requestPolicy: 'network-only'})
                  }
                  marginLeft="4"
                  size="sm"
                  aria-label="Refresh"
                  icon={<RepeatIcon />}
                ></IconButton>
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {data?.shops?.nodes?.map((shop) =>
              shop ? (
                <Tr key={shop._id}>
                  <Td>
                    <Link
                      color="blue.600"
                      to={`/shops/${shop._id}`}
                      as={ReactRouterLink}
                    >
                      <Flex align="center">
                        <Image
                          mr="2"
                          boxSize="24px"
                          borderRadius="sm"
                          src={shop.logo?.urls?.small as string}
                        ></Image>
                        {shop.name}
                      </Flex>
                    </Link>
                  </Td>
                  <Td>{shop.status}</Td>
                  <Td>{shop.rejectReason}</Td>
                  <Td>
                    {shop.proofFileUrl && (
                      <Link
                        color="blue.600"
                        isExternal
                        href={shop.proofFileUrl}
                      >
                        Proof file
                      </Link>
                    )}
                  </Td>
                  <Td>
                    <ApproveActions shopId={shop._id} />
                  </Td>
                </Tr>
              ) : null,
            )}
          </Tbody>
        </Table>
      </Stack>
    </Skeleton>
  );
};
