import {Alert, AlertIcon} from '@chakra-ui/alert';
import {Button, ButtonGroup} from '@chakra-ui/button';
import {useDisclosure} from '@chakra-ui/hooks';
import {Input} from '@chakra-ui/input';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/modal';
import {useState} from 'react';
import {
  useApproveShopMutation,
  useRejectShopMutation,
} from '../generated/graphql';

export const ApproveActions = ({shopId}: {shopId: string}) => {
  const {isOpen, onOpen, onClose} = useDisclosure();
  const [approveShopResult, approveShop] = useApproveShopMutation();
  const [rejectShopResult, rejectShop] = useRejectShopMutation();
  const [rejectReason, setRejectReason] = useState('');

  const error = approveShopResult.error || rejectShopResult.error;

  if (error) {
    return (
      <Alert status="error">
        <AlertIcon />
        Error: {error.message}
      </Alert>
    );
  }

  return (
    <>
      <ButtonGroup>
        <Button
          isLoading={approveShopResult.fetching}
          disabled={rejectShopResult.fetching}
          onClick={() => approveShop({shopId})}
          colorScheme="teal"
          size="sm"
        >
          Approve
        </Button>
        <Button
          isLoading={rejectShopResult.fetching}
          disabled={approveShopResult.fetching}
          onClick={onOpen}
          colorScheme="pink"
          size="sm"
        >
          Reject
        </Button>
      </ButtonGroup>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Reject reason</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input
              value={rejectReason}
              onChange={(event) => setRejectReason(event.target.value)}
              placeholder="Please enter reject reason"
            ></Input>
          </ModalBody>

          <ModalFooter>
            <Button
              disabled={rejectReason.length === 0}
              colorScheme="pink"
              mr={3}
              onClick={() => {
                rejectShop({shopId, reason: rejectReason});
                onClose();
              }}
            >
              Reject
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
