const strRequired = (key: string) =>{
  const value = process.env[key];

   if (typeof value  !== 'string') {
    throw new Error(`Invalid env var: ${key}: ${value}`)
  }

  return value
}

// export const API_URL = strRequired('REACT_APP_API_URL');
// export const AUTH_URL = strRequired('REACT_APP_AUTH_URL');
// export const AUTH_CLIENT_ID = strRequired('REACT_APP_AUTH_CLIENT_ID');
// export const AUTH_REALM = strRequired('REACT_APP_AUTH_REALM');

// export const API_URL = "https://gw.get1.app/graphql";
// export const AUTH_URL = "https://auth.get1.app/";
export const API_URL = "https://gw.stage.get1.app/graphql";
export const AUTH_URL = "https://auth.stage.get1.app/";
export const AUTH_CLIENT_ID = "get1-admin";
export const AUTH_REALM = "Get1st";