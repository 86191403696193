import {ReactKeycloakProvider} from '@react-keycloak/web';
import {createClient, Provider} from 'urql';
import {keycloak} from './keycloak';
import {ChakraProvider, extendTheme} from '@chakra-ui/react';
import {Router} from './Router';
import { API_URL } from './env';

const initOptions = {onLoad: 'login-required'};

const client = createClient({
  url: API_URL,
  fetchOptions: () => {
    return {
      headers: {
        authorization: keycloak.token ? `Bearer ${keycloak.token}` : '',
      },
    };
  },
});

const theme = extendTheme({
  styles: {
    global: {
      'a:focus:not(:focus-visible)': {
        boxShadow: 'none',
      },
    },
  },
});

function App() {
  return (
    <ChakraProvider theme={theme}>
      <ReactKeycloakProvider
        authClient={keycloak}
        initOptions={initOptions}
        LoadingComponent={<div>Keycloak loading...</div>}
        isLoadingCheck={(keycloak) => !keycloak.authenticated}
        onEvent={(event, error) => {
          console.log('onKeycloakEvent', event, error);
        }}
        onTokens={(tokens) => console.log('tokens:', tokens)}
      >
        <Provider value={client}>
          <Router />
        </Provider>
      </ReactKeycloakProvider>
    </ChakraProvider>
  );
}

export default App;
