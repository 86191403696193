import {Box, Flex, Link, Stack} from '@chakra-ui/layout';
import {Button} from '@chakra-ui/react';
import {useKeycloak} from '@react-keycloak/web';
import {
  BrowserRouter,
  NavLink,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import {HomePage} from './pages/Home/page';
import {ShopPage} from './pages/Shop/page';
import {AccessDeniedPage} from './pages/AccessDenied/page';

const PrivateRoute = ({component: Component, roles, ...rest}: any) => {
  const {keycloak} = useKeycloak();

  const isAuthorized = (roles: Array<string>) => {
    if (keycloak && roles) {
      return roles.some((r) => {
        const realm = keycloak.hasRealmRole(r);
        const resource = keycloak.hasResourceRole(r);
        return realm || resource;
      });
    }
    return false;
  };

  return (
    <Route
      {...rest}
      render={(props) => {
        return isAuthorized(roles) ? (
          <Component {...props} />
        ) : (
          <Redirect to="/access-denied" />
        );
      }}
    />
  );
};

export const Router = () => {
  const {keycloak} = useKeycloak();

  return (
    <BrowserRouter>
      <Stack>
        <Stack bg="Menu" padding={6}>
          <Flex align="center" justify="space-between">
            <Box>Welcome, {(keycloak.tokenParsed as any)?.email} </Box>
            <Box>
              <Button onClick={() => keycloak.logout()}>Log out</Button>
            </Box>
          </Flex>
          <Link to="/" as={NavLink}>
            Home
          </Link>
        </Stack>
        <Switch>
          <PrivateRoute
            roles={['get1-admin']}
            exact
            path="/"
            component={HomePage}
          />
          <PrivateRoute
            roles={['get1-admin']}
            path="/shops/:id"
            component={ShopPage}
          />
          <Route path="/access-denied" component={AccessDeniedPage}></Route>
        </Switch>
      </Stack>
    </BrowserRouter>
  );
};
